import React from 'react'
import './service-card.scss';
import { GoDotFill } from "react-icons/go";
import { NavLink } from 'react-router-dom';


const WhServiceCard = ({ data }) => {
   
    return (
        <div className="serviced-card-div green-color">
            <NavLink >
                <div className="card-top">
                    <div className="left">
                        <p>{new Date(data?.date).toDateString()}</p>
                        <GoDotFill />
                        <span className={`text-badge ${data?.wh_customer_status}-text`}>{data?.wh_customer_status}</span>
                    </div>
                    <div className={`right ${data?.wh_customer_status}-text`}>
                        <p>{data?.work_type?.toUpperCase()}</p>
                    </div>
                </div>
                <div className="card-content">
                    <div className="section-two-div">
                        <p className='primary' >{data?.work_description}</p>
                    </div>
                </div>
                <div className="card-bottom">
                    <div className="left">
                        <p>{data?.service_srl_number}</p>
                        <GoDotFill />
                        <p>{data?.work_type}</p>
                    </div>
                    <div className="rig">
                        <p>{data?.technician}</p>
                    </div>
                </div>
            </NavLink>
        </div>
    )
}

export default WhServiceCard