import React from 'react'
import './customer-profile-card.scss'
import { BsStarFill } from 'react-icons/bs'
import { RxDotFilled } from 'react-icons/rx'

const CustomerProfileCard1 = ({ imgObj, fullName, starRate, cid, pr, wh }) => {

    return (
        <div className="customer-profile-card-one-div">
            <div className="profile-info" >
                <div className={`profile-img`}>
                    {/* <img src="" alt="" /> */}
                    <h2 >{fullName?.slice(0, 1).toUpperCase()}</h2>
                </div>
                <div className="name-div">
                    <div>
                        <h4>{fullName}</h4>
                        <span className='text-badge green-text'><BsStarFill /> {starRate || 0}</span>
                    </div>
                    <div>
                        <p>CID : {cid}</p>
                        <RxDotFilled />
                        {pr && <span className={`text-badge purple-text`}>PR</span>}
                        {wh && <span className={`text-badge Normal-text`}>WH</span>}
                        {!pr && !wh && <span className={`text-badge `}>No products</span>}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default CustomerProfileCard1