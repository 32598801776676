import React from 'react'
import { Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import PageWrapper from './components/PageWrapper';
import './Fonts'
import Info from './components/Info';
import SparesList from './components/SparesList';
import Receipt from './components/Receipt';
import BottomSeal from './components/BottomSeal';

function BillPdf({ data }) {

    const styles = StyleSheet.create({
        head: {
            fontSize: '15px',
            fontWeight: '800',
            color: '#ac7c1c',
            marginTop: '15px'
        },
    })
    return (
        <Document>
            <PageWrapper>
                <View >
                    {/* Info */}
                    <Info data={data} />

                    {/* Receipt */}
                    <Text style={styles.head}>Summary</Text>
                    <Receipt data={data} sspRenewal={data?.service_data?.package_renewal && data?.service_data?.new_customer_status === 'SSP'} />

                    {(data?.primary_spares?.[0] || data?.secondary_spares?.[0] ||
                        data?.special_spares?.[0]) ? <Text style={styles.head}>Detail</Text> : <Text style={styles.head}></Text>}
                    {/* Primary List */}
                    {data?.primary_spares?.[0] &&
                        <SparesList type={'Primary'} data={data?.primary_spares} total={data?.bill_data?.primary_spare_total_receivable}
                            sspRenewal={data?.service_data?.package_renewal && data?.service_data?.new_customer_status === 'SSP'} />}

                    {/* Secondary List */}
                    {data?.secondary_spares?.[0] &&
                        <SparesList doBreak={data?.primary_spares?.[7] && true} type={'Secondary'} data={data?.secondary_spares}
                            total={data?.bill_data?.secondary_spare_total_receivable} />}

                    {/* Special work */}
                    {data?.special_spares?.[0] &&
                        <SparesList type={'Special work'} data={data?.special_spares} total={data?.special_work_spare_total_receivable} />
                    }

                    {/* Seal */}
                    <BottomSeal data={data} />
                </View>
            </PageWrapper>
        </Document>
    )
}

export default BillPdf