import React, { useEffect, useState } from 'react'
import './style.scss'
import { FaDotCircle } from "react-icons/fa";
import { customerAxios } from '../../../config/axios';
import { toast } from 'react-hot-toast'
import Spinner from '../../common/spinners/SpinWithMessage'
import { IoMdCheckmarkCircle, IoMdRemoveCircle } from "react-icons/io";
import { PiCardholderDuotone } from "react-icons/pi";
import ServiceCard from '../service-card/ServiceCard';
import WhServiceCard from '../service-card/WhServiceCard';
import InstallationCard from '../service-card/InstallationCard';
import { useNavigate } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating'

const CustomerCardDetails = ({ cid }) => {
    const [customer, setCustomer] = useState({})
    const [purifier, setPurifier] = useState({})
    const [wholeHouse, setWholeHouse] = useState({})
    const [cardList, setCardList] = useState([])
    const [whCardList, setWhCardList] = useState([])
    const [cardImageList, setCardImageList] = useState([])
    const [loading, setLoading] = useState('fetch')
    const [serviceCardShow, setServiceCardShow] = useState(0)
    const navigate = useNavigate()

    useEffect(() => {
        if (cid) {
            const customerDetails = customerAxios.get(`/profile?cid=${cid}&detail=YES`).then((response) => {
                setCustomer(response.data)
            })

            const purifierDetails = customerAxios.get(`/service/purifier?cid=${cid}&spare=YES`).then((response) => {
                setPurifier(response.data)
            })

            const serviceCardList = customerAxios.get(`/service/purifier/cards?cid=${cid}&spare=YES`).then((response) => {
                setCardList(response.data)
            })

            const whDetails = customerAxios.get(`/service/whole-house?cid=${cid}&spare=YES`).then((response) => {
                setWholeHouse(response.data)
            })

            const whCardList = customerAxios.get(`/service/whole-house/cards?cid=${cid}&spare=YES`).then((response) => {
                setWhCardList(response.data)
            })

            const cardImageList = customerAxios.get(`/service/image?cid=${cid}`).then((response) => {
                setCardImageList(response.data)
            })




            Promise.all([customerDetails, purifierDetails, whDetails, whCardList, serviceCardList, cardImageList])
                .then(() => setLoading('')).catch(() => {
                    toast.error('Some error, Try now!')
                    navigate(-1)
                })
        }
        // eslint-disable-next-line
    }, [cid])


    useEffect(() => {
        if (purifier?.purifier_customer_status) {
            setServiceCardShow(1)
        } else if (wholeHouse?.wh_customer_status) {
            setServiceCardShow(2)
        } else {
            setServiceCardShow(0)
        }
    }, [purifier, wholeHouse])



    return (
        <div className="customer-card-details">
            <div className="top-div">
                <h3>#{customer?.cid}<span>_{(customer?.full_name)?.toUpperCase()}</span></h3>
            </div>
            <div className="section-border-div">
                <div className="left-div">
                    {/* Personal Details */}
                    <div className="list-border-div">
                        <div className="list-title">
                            <FaDotCircle />
                            <h4>Personal details</h4>
                        </div>
                        {loading
                            ? <Spinner message='Loading...' height={'150px'} />
                            : <div className="title-content">
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>Address, Place</p>
                                    </div>
                                    <div className="two-div">
                                        <p>: {customer?.address?.address}, {customer?.address?.place}</p>
                                    </div>
                                </div>
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>Post, Land mark</p>
                                    </div>
                                    <div className="two-div">
                                        <p>: {customer?.address?.post} (P.O), {customer?.address?.land_mark}</p>
                                    </div>
                                </div>
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>District, Pin</p>
                                    </div>
                                    <div className="two-div">
                                        <p>: {customer?.address?.district}, {customer?.address?.pin_code}</p>
                                    </div>
                                </div>
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>State</p>
                                    </div>
                                    <div className="two-div">
                                        <p>: {customer?.address?.state}</p>
                                    </div>
                                </div>
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>Contact 1</p>
                                    </div>
                                    <div className="two-div">
                                        <p>: {customer?.contact1}</p>
                                    </div>
                                </div>
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>Contact 2</p>
                                    </div>
                                    <div className="two-div">
                                        <p>: {customer?.contact2}</p>
                                    </div>
                                </div>
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>Whatsapp</p>
                                    </div>
                                    <div className="two-div">
                                        <p>: {customer?.whatsapp1}</p>
                                    </div>
                                </div>
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>Zone</p>
                                    </div>
                                    <div className="two-div">
                                        <p>: {customer?.zone}</p>
                                    </div>
                                </div>
                                <div className="list-div credit-amt">
                                    <div className="one-div">
                                        <p>Credit amount</p>
                                    </div>
                                    <div className="two-div">
                                        <p>: ₹{customer?.credit_amount || 0}</p>
                                    </div>
                                </div>
                                <div className="list-div debit-amt">
                                    <div className="one-div">
                                        <p>Debit amount</p>
                                    </div>
                                    <div className="two-div">
                                        <p>: ₹{customer?.debit_amount || 0}</p>
                                    </div>
                                </div>
                            </div>}
                    </div>

                    {/* Purifier Service Details */}
                    {purifier?.purifier_customer_status && <div className="list-border-div">
                        <div className="list-title">
                            <FaDotCircle />
                            <h4>Purifier details</h4>
                        </div>
                        {loading
                            ? <Spinner message='Loading...' height={'150px'} />
                            : <div className="title-content">
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>Model</p>
                                    </div>
                                    <div className="two-div">
                                        <p>: {purifier?.product_name}</p>
                                    </div>
                                </div>
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>Model category</p>
                                    </div>
                                    <div className="two-div">
                                        <p>: {purifier?.product_category}</p>
                                    </div>
                                </div>
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>Product usage</p>
                                    </div>
                                    <div className="two-div">
                                        <p>: {purifier?.product_usage}</p>
                                    </div>
                                </div>
                                {purifier?.installed_at && <div className="list-div">
                                    <div className="one-div">
                                        <p>Installation date</p>
                                    </div>
                                    <div className="two-div">
                                        <p>{`: ${new Date(purifier?.installed_at).toDateString()}`}</p>
                                    </div>
                                </div>}
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>Purifier status</p>
                                    </div>
                                    <div className="two-div">
                                        <p>: <span className={`text-badge ${purifier?.purifier_customer_status}-text`}>{purifier?.purifier_customer_status}</span></p>
                                    </div>
                                </div>
                                {purifier?.package_id && <div className="list-div">
                                    <div className="one-div">
                                        <p>Package Id</p>
                                    </div>
                                    <div className="two-div">
                                        <p>: {purifier?.package_id}</p>
                                    </div>
                                </div>}
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>Package Start date</p>
                                    </div>
                                    <div className="two-div">
                                        <p>{`: ${purifier?.package_started_date ? new Date(purifier?.package_started_date).toDateString() : '-'}`}</p>
                                    </div>
                                </div>
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>Package Expiry date</p>
                                    </div>
                                    <div className="two-div">
                                        <p>{`: ${purifier?.package_expiry_date ? new Date(purifier?.package_expiry_date).toDateString() : '-'}`}</p>
                                    </div>
                                </div>
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>Carbon Start date</p>
                                    </div>
                                    <div className="two-div">
                                        <p>{`: ${purifier?.carbon_filter_start_date ? new Date(purifier?.carbon_filter_start_date).toDateString() : '-'}`}</p>
                                    </div>
                                </div>
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>Carbon Expiry date</p>
                                    </div>
                                    <div className="two-div">
                                        <p>{`: ${purifier?.carbon_filter_expiry_date ? new Date(purifier?.carbon_filter_expiry_date).toDateString() : '-'}`}</p>
                                    </div>
                                </div>
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>Next service date</p>
                                    </div>
                                    <div className="two-div">
                                        <p>{`: ${purifier?.next_periodical_service_date ? new Date(purifier?.next_periodical_service_date).toDateString() : '-'}`}</p>
                                    </div>
                                </div>
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>Service Count</p>
                                    </div>
                                    <div className="two-div">
                                        <div className="icons">
                                            {purifier?.service_count?.map((count, index) => (
                                                <span key={index} className={count?.status ? 'add' : 'delete'}>
                                                    {count?.status
                                                        ? <IoMdCheckmarkCircle title={count?.srl_number} />
                                                        : <IoMdRemoveCircle title={count?.reason} />}</span>
                                            ))}
                                            {!purifier?.service_count?.[0] && <p>: No services</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>Complaint count</p>
                                    </div>
                                    <div className="two-div">
                                        <div className="icons">{purifier?.complaint_count?.map((count, index) => (
                                            <span key={index} className={count?.status ? 'add' : 'delete'}>
                                                {count?.status
                                                    ? <IoMdCheckmarkCircle title={count?.srl_number} />
                                                    : <IoMdRemoveCircle title={count?.reason} />}</span>
                                        ))}
                                            {!purifier?.complaint_count?.[0] && <p>: No complaints</p>}
                                        </div>
                                    </div>
                                </div>
                                {purifier?.purifier_customer_status === 'SSP' && <div className="list-div">
                                    <div className="one-div">
                                        <p>SSP Card No</p>
                                    </div>
                                    <div className="two-div">
                                        <p>: {purifier?.ssp_card_number || '-'}</p>
                                    </div>
                                </div>}
                                {purifier?.purifier_customer_status === 'SSP' && <div className="list-div">
                                    <div className="one-div">
                                        <p>SSP Pending Tokens</p>
                                    </div>
                                    <div className="two-div">
                                        <p>: {purifier?.pending_ssp_token || 0}</p>
                                    </div>
                                </div>}
                            </div>}
                    </div>}

                    {/* WH Service Details */}
                    {wholeHouse?.wh_customer_status && <div className="list-border-div">
                        <div className="list-title">
                            <FaDotCircle />
                            <h4>Whole house details</h4>
                        </div>
                        {loading
                            ? <Spinner message='Loading...' height={'150px'} />
                            : <div className="title-content">
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>Model</p>
                                    </div>
                                    <div className="two-div">
                                        <p>: {wholeHouse?.product_name}</p>
                                    </div>
                                </div>
                                {wholeHouse?.installed_at && <div className="list-div">
                                    <div className="one-div">
                                        <p>Installation date</p>
                                    </div>
                                    <div className="two-div">
                                        <p>{`: ${new Date(wholeHouse?.installed_at).toDateString()}`}</p>
                                    </div>
                                </div>}
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>Whole house status</p>
                                    </div>
                                    <div className="two-div">
                                        <p>: <span className={`text-badge ${wholeHouse?.wh_customer_status}-text`}>{wholeHouse?.wh_customer_status}</span></p>
                                    </div>
                                </div>
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>Package Start date</p>
                                    </div>
                                    <div className="two-div">
                                        <p>{`: ${wholeHouse?.package_started_date ? new Date(wholeHouse?.package_started_date).toDateString() : '-'}`}</p>
                                    </div>
                                </div>
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>Package Expiry date</p>
                                    </div>
                                    <div className="two-div">
                                        <p>{`: ${wholeHouse?.package_expiry_date ? new Date(wholeHouse?.package_expiry_date).toDateString() : '-'}`}</p>
                                    </div>
                                </div>
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>Next service date</p>
                                    </div>
                                    <div className="two-div">
                                        <p>{`: ${wholeHouse?.next_periodical_service_date ? new Date(wholeHouse?.next_periodical_service_date).toDateString() : '-'}`}</p>
                                    </div>
                                </div>
                            </div>}
                    </div>}

                    {/* Star Details */}
                    <div className="list-border-div">
                        <div className="list-title">
                            <FaDotCircle />
                            <h4>Star rate</h4>
                        </div>
                        {loading
                            ? <Spinner message='Loading...' height={'150px'} />
                            : <div className="title-content">
                                <div className='star-rate-div'>
                                    <Rating allowFraction size={35} initialValue={customer?.star_rate || 0} readonly={true} />
                                    <h3>{parseFloat(customer?.star_rate || 0).toFixed(1)}</h3>
                                </div>


                                <div className="list-div">
                                    <div className="one-div">
                                        <p>Premium</p>
                                    </div>
                                    <div className="two-div" style={{ display: 'flex', gap: "10px" }}>
                                        : <Rating allowFraction size={20} initialValue={parseFloat((customer?.star_details?.premium || 0) * 5).toFixed(1)} readonly={true} />
                                        <p>{parseFloat((customer?.star_details?.premium || 0) * 5).toFixed(1)}</p>
                                    </div>
                                </div>
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>Behavior</p>
                                    </div>
                                    <div className="two-div" style={{ display: 'flex', gap: "10px" }}>
                                        : <Rating allowFraction size={20} initialValue={parseFloat((customer?.star_details?.behavior || 0) * 5).toFixed(1)} readonly={true} />
                                        <p>{parseFloat((customer?.star_details?.behavior || 0) * 5).toFixed(1)}</p>
                                    </div>
                                </div>
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>Service</p>
                                    </div>
                                    <div className="two-div" style={{ display: 'flex', gap: "10px" }}>
                                        : <Rating allowFraction size={20} initialValue={parseFloat((customer?.star_details?.service || 0) * 5).toFixed(1)} readonly={true} />
                                        <p>{parseFloat((customer?.star_details?.service || 0) * 5).toFixed(1)}</p>
                                    </div>
                                </div>
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>Continuity</p>
                                    </div>
                                    <div className="two-div" style={{ display: 'flex', gap: "10px" }}>
                                        : <Rating allowFraction size={20} initialValue={parseFloat((customer?.star_details?.continuity || 0) * 5).toFixed(1)} readonly={true} />
                                        <p>{parseFloat((customer?.star_details?.continuity || 0) * 5).toFixed(1)}</p>
                                    </div>
                                </div>
                                <div className="list-div">
                                    <div className="one-div">
                                        <p>Payment</p>
                                    </div>
                                    <div className="two-div" style={{ display: 'flex', gap: "10px" }}>
                                        : <Rating allowFraction size={20} initialValue={parseFloat((customer?.star_details?.payment || 0) * 5).toFixed(1)} readonly={true} />
                                        <p>{parseFloat((customer?.star_details?.payment || 0) * 5).toFixed(1)}</p>
                                    </div>
                                </div>
                            </div>}
                    </div>
                </div>
                <div className="right-div">
                    <div className="div-border">
                        <div className="list-title">
                            <FaDotCircle />
                            <h4>Service cards</h4>
                        </div>
                        <div className="color-info-div">
                            <div className="color-div">
                                <div className='color-box amount'></div>
                                <p>Amount</p>
                            </div>
                            <div className="color-div">
                                <div className='color-box primary'></div>
                                <p>Primary</p>
                            </div>
                            <div className="color-div">
                                <div className='color-box secondary'></div>
                                <p>Secondary</p>
                            </div>
                            <div className="color-div">
                                <div className='color-box special'></div>
                                <p>Special work</p>
                            </div>
                        </div>
                        <div className="button-div">
                            <div className="left">
                                {purifier?.purifier_customer_status && <div className={serviceCardShow === 1 ? 'menu-button active' : 'menu-button'}
                                    onClick={() => setServiceCardShow(1)}>Purifier</div>}
                                {wholeHouse?.wh_customer_status && <div className={serviceCardShow === 2 ? 'menu-button active' : 'menu-button'}
                                    onClick={() => setServiceCardShow(2)}>Whole house</div>}
                            </div>
                        </div>
                        {serviceCardShow === 1 && <div className="card-list-div">
                            {!cardList?.[0] && !cardImageList?.[0] &&
                                <Spinner height={'300px'} message='No service cards' icon={<PiCardholderDuotone />} spin={false} />}
                            {cardList?.[0] &&
                                <>{cardList?.map((card, index) => {
                                    if (card?.installation) {
                                        return <InstallationCard key={index} data={card} />
                                    } else if (card?.re_installation) {
                                        return <InstallationCard key={index} data={card} re={true} />
                                    } else if (card?.service_card) {
                                        return <ServiceCard key={index} data={card} />
                                    }
                                    return null
                                })}</>}
                        </div>}
                        {serviceCardShow === 2 && <div className="card-list-div">
                            {!whCardList?.[0] && !cardImageList?.[0] &&
                                <Spinner height={'300px'} message='No service cards' icon={<PiCardholderDuotone />} spin={false} />}
                            {whCardList?.[0] &&
                                <>{whCardList?.map((card, index) => {
                                    if (card?.installation) {
                                        return <InstallationCard key={index} data={card} />
                                    } else if (card?.re_installation) {
                                        return <InstallationCard key={index} data={card} re={true} />
                                    } else if (card?.service_card) {
                                        return <WhServiceCard key={index} data={card} />
                                    }
                                })}</>}
                        </div>}
                        <div className="card-image-div">
                            {cardImageList?.[0] && <>
                                {cardImageList?.map((card) => <img alt={card.image.key} src={card.image.url} />)}
                            </>}
                        </div>
                    </div>

                </div>
            </div>
        </div >
    )
}

export default CustomerCardDetails