import React from 'react'
import './service-card.scss';
import { GoDotFill } from "react-icons/go";
import { NavLink } from 'react-router-dom';


const InstallationCard = ({ data, re }) => {

    return (
        <div className="serviced-card-div green-color install-card">
            <NavLink to={''}>
                <div className="card-top">
                    <div className="left">
                        <p>{new Date(data?.date).toDateString()}</p>
                        <GoDotFill />
                        <span className={`text-badge`}>{data?.type_of_product}</span>
                    </div>
                    <div className={`right green-text`}>
                        <p>{re ? 'RE-INSTALLATION' : 'INSTALLATION'}</p>
                    </div>
                </div>
                <div className="card-content">
                    <div className="section-one-div">
                        {!(!data?.grand_total_receivable) &&
                            <p className='amount'><span>₹</span>{data?.grand_total_receivable}</p>}
                    </div>
                    <div className="section-two-div">
                        {data?.purifier_name
                            && <p className='special'>{data?.purifier_name}<span>{'Purifier name'}</span></p>
                        }
                        {data?.purifier_usage
                            && <p className='special'>{data?.purifier_usage}<span>{'Purifier usage'}</span></p>
                        }
                        {data?.pr_description
                            && <p className='special'>{data?.pr_description}<span>{'Purifier Decs'}</span></p>
                        }
                        {data?.wh_name
                            && <p className='special'>{data?.wh_name}<span>{'WH name'}</span></p>
                        }
                        {data?.wh_description
                            && <p className='special'>{data?.wh_description}<span>{'WH Desc'}</span></p>
                        }
                    </div>
                </div>
                <div className="card-bottom">
                    <div className="left">
                        <p>{data?.service_srl_number}</p>
                        <GoDotFill />
                        <p>{data?.mode_of_installation}</p>
                    </div>
                    <div className="rig">
                        <p>{data?.technician}</p>
                    </div>
                </div>
            </NavLink>
        </div>
    )
}

export default InstallationCard