import React from 'react'
import { View, Text, StyleSheet } from '@react-pdf/renderer';

function SparesList({ type, data, total, doBreak, sspRenewal }) {
    const styles = StyleSheet.create({
        head: {
            fontSize: '15px',
            fontWeight: '800',
            color: '#ac7c1c'
        },
        table: {
            display: 'table',
            width: '100%',
            marginTop: '5px',
            borderColor: '#ededed',
            borderStyle: 'solid',
            borderWidth: 1,
            marginBottom: '20px'
        },
        tableRow: {
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            borderBottomStyle: 'dashed',
            borderBottomColor: '#b8b8b8',
            borderBottomWidth: 1
        },
        tableCell: {
            flex: 1,
            fontSize: '11px',
            padding: 5,
            textAlign: 'start',
        },
        totalRow: {
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            borderBottomStyle: 'dashed',
            borderBottomColor: '#b8b8b8',
            borderBottomWidth: 1
        },
        totalCell: {
            backgroundColor: '#cccccc',
            flex: 1,
            fontSize: '12px',
            fontWeight: 700,
            padding: 5,
            textAlign: 'start',
        },
        headerRow: {
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            borderBottomStyle: 'solid',
            borderBottomColor: 'gray',
            borderBottomWidth: 1
        },
        headerCell: {
            flex: 1,
            fontSize: '12px',
            fontWeight: 700,
            padding: 5,
            textAlign: 'start',
        },
        infoText: {
            textAlign: 'right',
            fontSize: '9px'
        }
    })
    return (
        <View>
            {doBreak && <Text break={true}></Text>}

            <View style={styles.table}>
                {/* Headers */}
                <View style={styles.headerRow}>
                    <Text style={styles.headerCell}>No</Text>
                    <Text style={{ ...styles.headerCell, flex: 4 }}>{type} spares name</Text>
                    <Text style={styles.headerCell}>MRP</Text>
                    {(data?.[0]?.rate === 0 || sspRenewal) ? <>
                        <Text style={styles.headerCell}>Rate</Text>
                    </> : data?.[0]?.mrp !== data?.[0]?.rate
                        ? <>
                            <Text style={styles.headerCell}>Disc(%)</Text>
                            <Text style={styles.headerCell}>SSP Rate</Text>
                        </> : <></>}
                    <Text style={styles.headerCell}>Qty</Text>
                    <Text style={styles.headerCell}>Amount</Text>
                </View>

                {/* Body */}
                {data?.map((spare, index) => <View key={index} style={styles.tableRow}>
                    <Text style={styles.tableCell}>{index + 1}</Text>
                    <Text style={{ ...styles.tableCell, flex: 4 }}>{spare.spare_name}</Text>
                    <Text style={styles.tableCell}>₹{spare.mrp || 0}</Text>
                    {(data?.[0]?.rate === 0 || sspRenewal) ? <>
                        <Text style={styles.tableCell}>₹{sspRenewal ? 0 : (spare.rate || 0)}</Text>
                    </> : data?.[0]?.mrp !== data?.[0]?.rate
                        ? <>
                            <Text style={styles.tableCell}>{spare.discount_percentage ? `${spare.discount_percentage}%` : '-'}</Text>
                            <Text style={styles.tableCell}>₹{spare.rate || 0}</Text>
                        </> : <></>}
                    <Text style={styles.tableCell}>{spare.quantity}</Text>
                    <Text style={styles.tableCell}>₹{sspRenewal ? 0 : (Number(spare.rate) * Number(spare.quantity) || 0)} {spare?.warranty && '(Warr)'}</Text>
                </View>
                )}

                <View style={styles.totalRow}>
                    <Text style={styles.totalCell}></Text>
                    <Text style={{ ...styles.totalCell, flex: 4 }}>Total</Text>
                    <Text style={styles.totalCell}></Text>
                    {data?.[0]?.rate === 0 ? <>
                        <Text style={styles.totalCell}></Text>
                    </> : data?.[0]?.mrp !== data?.[0]?.rate
                        ? <>
                            <Text style={styles.totalCell}></Text>
                            <Text style={styles.totalCell}></Text>
                        </> : <></>}
                    <Text style={styles.totalCell}></Text>
                    <Text style={styles.totalCell}>{sspRenewal ? 0 : `₹${total || 0}`}</Text>
                </View>
            </View>

        </View >
    )
}

export default SparesList