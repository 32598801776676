import './app.scss';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { connection } from './redux/features/networkSlice'
import { toast } from 'react-hot-toast'
import { Routes, Route } from 'react-router-dom'
import User from './routes/User';
import BillReceiptBuilder from './pages/bill-receipt-builder/BillReceiptBuilder'

function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(connection(navigator.onLine))
    const handleOnline = () => {
      dispatch(connection(true))
      toast.success('Back online')
    };

    const handleOffline = () => {
      dispatch(connection(false))
      toast.error('No connection')
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="App">
      <Routes>

        {/* Public Router */}
        <Route path='/payment/pdf-generator' element={<BillReceiptBuilder />} />

        {/* Private Router */}
        <Route element={<User />} path='/*' />

      </Routes>
    </div>
  );
}

export default App;