import React from 'react'
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { formatStringDate } from '../../../assets/javascript/formate-functions'

function ReceiptInfo({ data }) {
    const styles = StyleSheet.create({
        parent: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            margin: '8px 0',
            marginBottom: '15px',
            paddingBottom: '15px',
            borderBottom: '1px solid #d4d4d4'
        },
        head: {
            fontWeight: 600,
            fontSize: '11px',
            marginBottom: '7px',
        },
        text: {
            marginBottom: '2px',
            fontSize: '11px',
            fontWeight: 400
        }
    })
    return (

        <View style={styles.parent} fixed>
            <View style={{ textAlign: 'left' }}>
                <Text style={styles.head}>From,</Text>
                <Text style={styles.text}>Alliance Water Solutions</Text>
                <Text style={styles.text}>Chalingad PO</Text>
                <Text style={styles.text}>Kaipamangalam, Thrissur</Text>
                <Text style={styles.text}>Pin code: 680 681</Text>
                <Text style={styles.text}>Mobile: 953 953 9453</Text>
            </View>
            <View style={{ textAlign: 'left' }}>
                <Text style={styles.head}>Sold To,</Text>
                <Text style={styles.text}>Customer ID : {data?.cid}</Text>
                <Text style={styles.text}>{data?.name_of_customer}</Text>
                {data?.address?.address && <Text style={styles.text}>{data?.address?.address}</Text>}
                {(data?.address?.place || data?.address?.post) &&
                    <Text style={styles.text}>{data?.address?.place && `${data?.address?.place},`} {data?.address?.post && `${data?.address?.post} (P.O)`}</Text>}
                {(data?.address?.district || data?.address?.pin_code) &&
                    <Text style={styles.text}>{data?.address?.district && `${data?.address?.district} Dt. `} {data?.address?.pin_code && `- ${data?.address?.pin_code}`}</Text>}
                <Text style={styles.text}>Mobile : {data?.contact1?.number || data?.contact2?.number || "Nill"}</Text>
            </View>
            <View style={{ textAlign: 'left' }}>
                <Text style={styles.text}>Payment date : {data?.payment_history?.[0]
                    ? formatStringDate(data?.payment_history[data?.payment_history.length - 1].received_date) : formatStringDate(data?.date)}</Text>
                <Text style={styles.text}>Payment method :{data?.payment_history?.[0] && data?.payment_history[0].received_method}</Text>
                <Text style={styles.text}>Work srl No  : {data?.service_srl_number}</Text>
                <Text style={styles.text}>Receipt No : {data?.bill_data?.receipt_srl_no}</Text>
            </View>
        </View>

    )
}

export default ReceiptInfo