import React, { useState } from 'react'
import * as XLSX from 'xlsx';
import './search-customer.scss'
import SinglePage from '../../components/common/page/SinglePage'
import NormalInput from '../../components/common/inputs/NormalInput'
import SelectInput from '../../components/common/inputs/SelectInput'
import CheckboxInput from '../../components/common/inputs/CheckboxInput'
import { BiLoaderAlt } from 'react-icons/bi'
import { customerAxios } from '../../config/axios'
import { toast } from 'react-hot-toast'
import Spinner from '../../components/common/spinners/SpinWithMessage'
import { FcSearch } from "react-icons/fc";
import { FcDeleteDatabase } from "react-icons/fc";
import { NavLink, useNavigate } from 'react-router-dom'
import { FaStar } from 'react-icons/fa'
import InfoBox from '../../components/common/info-box/InfoBox'
import { FiEdit2, FiDownload } from "react-icons/fi";
import { useSelector } from 'react-redux'
import { YYYYMMDDFormat } from '../../assets/javascript/formate-functions'
import { thisMonthFirstDay, thisMonthLastDay } from '../../assets/javascript/find-functions'



const SearchCustomer = () => {
    const [form, setForm] = useState({
        search: '',
        products: [],
        customer_status: [],
        flt_type: '',
        flt_from: YYYYMMDDFormat(thisMonthFirstDay(new Date())),
        flt_to: YYYYMMDDFormat(thisMonthLastDay(new Date()))
    })
    const [loading, setLoading] = useState('')
    const [data, setData] = useState([])
    const [ifSearch, setIfSearch] = useState(false)
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.userAuth)
    const [filtrationTypes, setFiltrationTypes] = useState([
        { option: 'Installation', value: 'installed_at' },
        { option: 'Next periodical service', value: 'next_periodical_service_date' },
        { option: 'Package start', value: 'package_started_date' },
        { option: 'Package expiry', value: 'package_expiry_date' },
        { option: 'Carbon usage start', value: 'carbon_filter_start_date' },
        { option: 'Carbon usage expiry', value: 'carbon_filter_expiry_date' },
        { option: 'Tech last visited', value: 'technician_last_visited_date' },
    ])

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const productChange = (e) => {

        const product = form.products.includes(e.target.value)
        if (product) {
            setForm({
                ...form,
                products: form.products.filter((value) => e.target.value !== value),
                customer_status: [],
                flt_type: '',
                flt_from: YYYYMMDDFormat(thisMonthFirstDay(new Date())),
                flt_to: YYYYMMDDFormat(thisMonthLastDay(new Date()))
            })

        } else {
            setForm({
                ...form,
                products: [...form.products, e.target.value],
                customer_status: [],
                flt_type: '',
                flt_from: YYYYMMDDFormat(thisMonthFirstDay(new Date())),
                flt_to: YYYYMMDDFormat(thisMonthLastDay(new Date()))
            })
            if (e.target.value === 'purifier') {
                setFiltrationTypes([
                    { option: 'Installation', value: 'installed_at' },
                    { option: 'Next periodical service', value: 'next_periodical_service_date' },
                    { option: 'Package start', value: 'package_started_date' },
                    { option: 'Package expiry', value: 'package_expiry_date' },
                    { option: 'Carbon usage start', value: 'carbon_filter_start_date' },
                    { option: 'Carbon usage expiry', value: 'carbon_filter_expiry_date' },
                    { option: 'Tech last visited', value: 'technician_last_visited_date' },
                ])
            } else if (e.target.value === 'whole_house') {
                setFiltrationTypes([
                    { option: 'Installation', value: 'installed_at' },
                    { option: 'Next periodical service', value: 'next_periodical_service_date' },
                    { option: 'Package start', value: 'package_started_date' },
                    { option: 'Package expiry', value: 'package_expiry_date' },
                    { option: 'Tech last visited', value: 'technician_last_visited_date' },
                ])
            }
        }
    }

    const handleCustomerStatusChange = (e) => {
        const customer_status = form.customer_status.includes(e.target.value)
        if (customer_status) {
            setForm({
                ...form,
                customer_status: form.customer_status.filter((value) => e.target.value !== value)
            })
        } else {
            setForm({
                ...form,
                customer_status: [...form.customer_status, e.target.value]
            })
        }
    }

    const handleApply = (e) => {
        e.preventDefault();

        if (form.search && form.search.length < 3) {
            toast.error('Enter at least 3 characters')
            return;
        }
        setLoading('filter')
        customerAxios.get(`/search?key=${form.search}&products=${form.products}&customer_status=${form.customer_status}&flt_type=${form.flt_type}&flt_from=${form.flt_from}&flt_to=${form.flt_to}`)
            .then((response) => {
                setIfSearch(true)
                setData(response.data)
                setLoading('')
            }).catch((error) => {
                toast.error(error.message)
                setLoading('')
            })
    }

    // Download Handle

    const downloadFile = (workbook, filename) => {
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        filename = filename + '.xlsx';

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            // For IE browser
            window.navigator.msSaveOrOpenBlob(data, filename);
        } else {
            // For other browsers
            const url = window.URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href = url;
            link.download = filename;
            link.click();
            window.URL.revokeObjectURL(url);
        }
    }

    const exportToExcel = (customer) => {

        const workbook = XLSX.utils.book_new();
        // DAR Setup
        const sheetName1 = 'Sheet 1';
        let workSheetData1 = []
        customer.map((data, index) => {
            const obj = {
                'CID': data?.cid,
                'FULL NAME': data?.full_name,
                'STAR RATE': data?.star_rate,
                'ADDRESS': data?.address?.address,
                'PLACE': data?.address?.place,
                'POST OFFICE': data?.address?.post,
                'PIN': data?.address?.pin_code,
                'LANDMARK': data?.address?.land_mark,
                'DISTRICT': data?.address?.district,
                'STATE': data?.address?.state,
                'CONTACT 1': data?.contact1,
                'CONTACT 2': data?.contact2,
                'ZONE': data?.zone,
                'PURIFIER STATUS': data?.purifier_customer_status,
                'WH STATUS': data?.wh_customer_status,
                'DELETED': data?.delete ? "Yes" : 'No',
                'BLOCKED': data?.blocked ? "Yes" : 'No',
            }
            workSheetData1.push(obj)
            return obj;
        })

        const worksheet1 = XLSX.utils.json_to_sheet(workSheetData1);
        XLSX.utils.book_append_sheet(workbook, worksheet1, sheetName1);

        return workbook;
    };

    const handleDownload = () => {
        if (data?.[0]) {
            const workbook = exportToExcel(data || [])
            downloadFile(workbook, `Customer Filter`)
        } else {
            toast.error('Data not available')
        }
    }


    return (
        <div className="search-customer-div">
            <SinglePage titleArray={['Search customer']}>
                <div className="search-border-div">
                    <div className="filter-border-div">
                        <form action="" onSubmit={handleApply}>
                            <div className="search-section">
                                <h5>Search</h5>
                                <InfoBox description={'Your can search CID, Name, Address, Place, Post and Contacts '} />
                                <NormalInput label='Enter at least 3 characters' name='search' value={form.search} onChangeFun={handleChange} isRequired={false} />
                            </div>
                            <div className="search-section">
                                <h5>Product type</h5>
                                <CheckboxInput label={'Purifier'} name={'product'} value={'purifier'} isRequired={false} onChangeFun={productChange} />
                                <CheckboxInput label={'Whole house'} name={'product'} value={'whole_house'} isRequired={false} onChangeFun={productChange} />
                                <CheckboxInput label={'Both'} name={'product'} value={'both'} isRequired={false} onChangeFun={productChange} />
                                <CheckboxInput label={'No one'} name={'product'} value={'no_one'} isRequired={false} onChangeFun={productChange} />
                            </div>
                            {form?.products.length === 1 && (form?.products.includes('purifier') || form?.products.includes('whole_house')) && <>
                                <div className="search-section">
                                    <h5>Customer status</h5>
                                    <CheckboxInput label={'AMC'} name={'customer_status'} value={'AMC'} isRequired={false} onChangeFun={handleCustomerStatusChange}
                                        disabled={!form?.products.includes('purifier')} />
                                    <CheckboxInput label={'SSP'} name={'customer_status'} value={'SSP'} isRequired={false} onChangeFun={handleCustomerStatusChange} />
                                    <CheckboxInput label={'I/W'} name={'customer_status'} value={'I/W'} isRequired={false} onChangeFun={handleCustomerStatusChange} />
                                    <CheckboxInput label={'O/W'} name={'customer_status'} value={'O/W'} isRequired={false} onChangeFun={handleCustomerStatusChange} />
                                    <CheckboxInput label={'O/C'} name={'customer_status'} value={'O/C'} isRequired={false} onChangeFun={handleCustomerStatusChange} />
                                </div>

                                <div className="search-section">
                                    <h5>More options</h5>
                                    <SelectInput label={'Filtration types'} name={'flt_type'} isRequired={false} onChangeFun={handleChange}
                                        firstOption={{ option: 'Choose', value: '' }} values={filtrationTypes} />
                                    <NormalInput label={'From date'} name={'flt_from'} value={form?.flt_from || YYYYMMDDFormat(thisMonthFirstDay(new Date()))} type={'date'}
                                        isRequired={false} onChangeFun={handleChange} />
                                    <NormalInput label={'To date'} name={'flt_to'} value={form?.flt_to || YYYYMMDDFormat(thisMonthLastDay(new Date()))} type={'date'}
                                        isRequired={false} onChangeFun={handleChange} />
                                </div>
                            </>}
                            <div className="button-div">
                                <button type='submit'>{loading === 'filter' ? <span className='loading-icon'><BiLoaderAlt /></span> : "Filter"}</button>
                            </div>
                        </form>
                    </div>
                    <div className="result-div">
                        <div className="header-div">
                            {ifSearch ? <h4>{data?.length || '0'} {data?.length > 1 ? 'Results' : 'Result'}</h4>
                                : <h4>Find Customers</h4>
                            }
                            <div className="right-div">
                                <span className='text-badge purple-text'>PURIFIER</span>
                                <span className='text-badge Normal-text'>VESSEL</span>
                            </div>
                        </div>
                        <div className="items-div">
                            {data?.[0] ?
                                <>
                                    {data.map((customer, index) => <div className="profile-div" key={index}>
                                        <NavLink to={`/customer/${customer.cid}/full-view`}>
                                            <div className="profile-image-div">
                                                <h3>{customer.full_name?.slice(0, 1).toUpperCase()}</h3>
                                                <span className='text-badge green-text'><FaStar /> {customer?.star_rate || 0}</span>
                                            </div>
                                            <p>CID : {customer.cid}</p>
                                            <h4>{customer.full_name}</h4>
                                            <p>{customer?.address?.address}, {customer?.address?.place},</p>
                                            <p>P.O {customer?.address?.post}, {customer?.address?.pin_code},</p>
                                            <p>{customer?.contact1}, {customer?.contact2}</p>
                                            <div>
                                                {customer?.purifier_customer_status && <span className='text-badge purple-text'>{customer?.purifier_customer_status}</span>}
                                                {customer?.wh_customer_status && <span className='text-badge Normal-text'>{customer?.wh_customer_status}</span>}
                                            </div>
                                        </NavLink>
                                        {user?.designation?.allow_origins?.includes('ControlNex') &&
                                            <div className="hover-view-div">
                                                <div className="icon-with-action-div" onClick={() => navigate(`/customer/${customer.cid}/edit`)}>
                                                    <FiEdit2 />
                                                </div>
                                            </div>}
                                    </div>)}

                                </>
                                : <>
                                    <Spinner height={'300px'} spin={false} message={ifSearch ? 'No matched data' : 'Search customer'}
                                        icon={ifSearch ? <FcDeleteDatabase /> : <FcSearch />} />
                                </>}
                        </div>
                        {data?.[0] && <div className="download-button" title='Download XL file' onClick={handleDownload}>
                            <FiDownload />
                        </div>}

                    </div>
                </div>
            </SinglePage>
        </div>
    )
}

export default SearchCustomer