import React, { useEffect, useState } from 'react'
import './edit-customer.scss'
import SinglePage from '../../components/common/page/SinglePage'
import { useNavigate, useParams } from 'react-router-dom'
import { customerAxios, purifierAxios } from '../../config/axios'
import toast from 'react-hot-toast'
import NormalInput from '../../components/common/inputs/NormalInput'
import SelectInput from '../../components/common/inputs/SelectInput'
import CustomerProfileCard1 from '../../components/user/customer-profile-card-1/CustomerProfileCard1'
import Spinner from '../../components/common/spinners/SpinWithMessage'
import { BiLoaderAlt } from 'react-icons/bi'

const EditCustomer = () => {
    const { cid } = useParams()
    const navigate = useNavigate()
    const [data, setData] = useState({})
    const [form, setForm] = useState({})
    const [zoneList, setZoneList] = useState([])
    const [states, setStates] = useState([])
    const [districts, setDistricts] = useState([])
    const [posts, setPosts] = useState([])
    const [loading, setLoading] = useState('fetch')

    const handleNormalChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

        if (e.target.name === 'state') {
            states?.map((obj) => {
                if (obj.option === e.target.value) {
                    const dtList = (obj?.districts || [])?.map((dt) => ({ option: dt.name, value: dt.name, posts: dt.posts }))
                    setDistricts([...dtList, { option: 'other', value: 'other' }])
                }
                return obj;
            })

            setForm({
                ...form,
                [e.target.name]: e.target.value,
                district: null,
                post: null
            })
        }

        if (e.target.name === 'district') {
            districts?.map((obj) => {
                if (obj.option === e.target.value) {
                    const postList = (obj?.posts || [])?.map((po) => ({ option: po.name, value: po.name, pin: po.pin_code }))
                    setPosts([...postList, { option: 'other', value: 'other' }])
                }
                return obj;
            })

            setForm({
                ...form,
                [e.target.name]: e.target.value,
                post: null
            })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading('submit')
        customerAxios.put('/profile', form).then(() => {
            toast.success('Updated!')
            setLoading('')
        }).catch((error) => {
            toast.error(error.message)
            setLoading('')
        })
    }

    useEffect(() => {

        customerAxios.get(`/profile?cid=${cid}`).then((response) => {
            if (response.data.cid) {
                const customerDetails = {
                    cid: response?.data?.cid || '',
                    full_name: response?.data?.full_name || '',
                    star_rate: response?.data?.star_rate || '',
                    first_name: response?.data?.first_name || '',
                    last_name: response?.data?.last_name || '',
                    address: response?.data?.address?.address || '',
                    place: response?.data?.address?.place || '',
                    pin_code: response?.data?.address?.pin_code || '',
                    land_mark: response?.data?.address?.land_mark || '',
                    post: response?.data?.address?.post || '',
                    district: response?.data?.address?.district || '',
                    state: response?.data?.address?.state || '',
                    contact_1: response?.data?.contact1 || '',
                    contact_2: response?.data?.contact2 || '',
                    whatsapp: response?.data?.whatsapp1 || '',
                    zone_id: response?.data?.zone_id || '',
                    purifier_customer_status: response?.data?.purifier_customer_status || '',
                    wh_customer_status: response?.data?.wh_customer_status || '',
                    care_of_id: response?.data?.care_of_id || ''

                }
                setData(customerDetails)
                setForm(customerDetails)

                purifierAxios.get('/zone').then((zoneRes) => {
                    setZoneList((zoneRes?.data || []).map((obj) => ({ option: obj.zone, value: obj.zone_id, selected: obj.zone_id === customerDetails.zone_id })))
                })

                customerAxios.get('/post-office-list').then((response) => {
                    let dtList = [], poList = []
                    // State
                    const stateList = (response?.data || []).map((obj) => {
                        if (obj.state === customerDetails?.state) {
                            dtList = obj.districts
                            return { option: obj.state, value: obj.state, districts: obj.districts, selected: true }
                        }
                        return { option: obj.state, value: obj.state, districts: obj.districts, }
                    })
                    setStates([...stateList, { option: 'other', value: 'other' }])

                    // District
                    const districtList = dtList.map((obj) => {
                        if (obj.name === customerDetails?.district) {
                            poList = obj.posts
                            return { option: obj.name, value: obj.name, posts: obj.posts, selected: true }
                        }
                        return { option: obj.name, value: obj.name, posts: obj.posts }
                    })
                    setDistricts([...districtList, { option: 'other', value: 'other' }])

                    // Post
                    const postList = poList.map((obj) => {
                        if (obj.name === customerDetails?.post) {
                            return { option: obj.name, value: obj.name, selected: true }
                        }
                        return { option: obj.name, value: obj.name }
                    })
                    setPosts([...postList, { option: 'other', value: 'other' }])

                    setLoading('')
                })

            } else {
                toast.error('Not a valid CID')
                navigate('/')
                setLoading('')
            }
        }).catch((error) => {
            toast.error(error.message)
            navigate('/')
            setLoading('')
        })
        // eslint-disable-next-line
    }, [])

    return (
        <div className="edit-customer-div">
            <SinglePage titleArray={[`Edit customer - ${cid}`]}>
                {loading === 'fetch'
                    ? <>
                        <Spinner message='Fetch data...' spin={true} />
                    </>
                    : <>
                        <form action="" onSubmit={handleSubmit}>
                            <div className="profile-badge-div">
                                <CustomerProfileCard1 fullName={data?.full_name} starRate={data?.star_rate} cid={data?.cid}
                                    pr={data?.purifier_customer_status} wh={data?.wh_customer_status} />
                            </div>
                            <div className="inputs">
                                <NormalInput label='First name*' name='first_name' value={form?.first_name} onChangeFun={handleNormalChange} />
                                <NormalInput label='Last name*' name='last_name' value={form?.last_name} onChangeFun={handleNormalChange} />
                                <NormalInput label='Address' name='address' value={form?.address} onChangeFun={handleNormalChange} isRequired={false} />
                                <NormalInput label='Place*' name='place' value={form?.place} onChangeFun={handleNormalChange} />
                                <SelectInput label='State*' name='state' values={states} firstOption={{ option: 'choose', value: "" }} onChangeFun={handleNormalChange} />
                                <SelectInput label='District*' name='district' values={districts} firstOption={{ option: 'choose', value: "" }} onChangeFun={handleNormalChange} />
                                <SelectInput label='Post*' name='post' values={posts} firstOption={{ option: 'choose', value: "" }} onChangeFun={handleNormalChange} />
                                <NormalInput label='Pin code' name='pin_code' value={form?.pin_code} onChangeFun={handleNormalChange} isRequired={false} />
                                <NormalInput label='Land mark' name='land_mark' value={form?.land_mark} onChangeFun={handleNormalChange} isRequired={false} />
                                <NormalInput label='Contact 1*' name='contact_1' value={form?.contact_1} type={'number'} onChangeFun={handleNormalChange}
                                    min={5555555555} max={9999999999} />
                                <NormalInput label='Contact 2' name='contact_2' value={form?.contact_2} type={'number'} onChangeFun={handleNormalChange}
                                    isRequired={false} />
                                <NormalInput label='Whatsapp' name='whatsapp' value={form?.whatsapp} type={'number'} onChangeFun={handleNormalChange}
                                    isRequired={false} min={5555555555} max={9999999999} />
                                <SelectInput label='Zone*' name='zone_id' values={zoneList} firstOption={{ option: 'choose', value: "" }} onChangeFun={handleNormalChange} />
                                <NormalInput label='My Care of ID' name='care_of_id' value={form?.care_of_id} onChangeFun={handleNormalChange} isRequired={false} />
                            </div>
                            <div className="button-div">
                                <button type='submit'>{loading === 'submit' ? <span className='loading-icon'><BiLoaderAlt /></span> : "Update"}</button>
                            </div>
                        </form>
                    </>}
            </SinglePage>
        </div>
    )
}

export default EditCustomer