import React, { useEffect, useState } from 'react'
import './home.scss'
import SinglePage from '../../components/common/page/SinglePage'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Modal from '../../components/common/modal/Modal'
import AddCustomer from '../../components/user/add-customer/AddCustomer'
import CustomerDownloadBox from '../../components/user/customer-download-box/CustomerDownloadBox'


function Home() {
    const { user } = useSelector((state) => state.userAuth)
    const [searchParams, setSearchParams] = useSearchParams();
    const [modal, setModal] = useState({ content: null, title: null, status: false })
    const navigate = useNavigate()

    useEffect(() => {
        const id = searchParams.get('id');
        if (!id && user._id) {
            setSearchParams(`id=${user._id}`)
        }
        // eslint-disable-next-line
    }, [])

    const openModal = (elem, head, width) => {
        setModal({
            content: elem,
            title: head,
            status: true,
            width
        })
    }

    return (
        <div className="home-page-div">
            <Modal modal={modal} setModal={setModal} />
            <SinglePage titleArray={[`Name : ${user.first_name} ${user.last_name}`]}>
                <div className="home-border">
                    {user?.designation?.allow_origins?.includes('ControlNex') &&
                        <div className="button-div" >
                            <button onClick={() => openModal(<AddCustomer />, 'CREATE NEW CUSTOMER', '600px')} >CREATE NEW CUSTOMER</button>
                        </div>}

                    <div className="button-div" >
                        <button onClick={() => navigate('/customer/search')} >SEARCH A CUSTOMER</button>
                    </div>

                    {user?.designation?.allow_origins?.includes('ControlNex') &&
                        <div className="button-div" >
                            <button onClick={() => openModal(<CustomerDownloadBox setModal={setModal} />, 'DOWNLOAD CUSTOMER LIST',)} >DOWNLOAD CUSTOMER LIST</button>
                        </div>}

                </div>
            </SinglePage >
        </div >
    )
}

export default Home