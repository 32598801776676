export const thisMonthLastDay = (ISODate) => {
    const today = new Date(ISODate || new Date()); // This gets the current date and time
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const lastDayISO = new Date(lastDayOfMonth.setHours(23, 59, 59, 999)).toISOString();
    return lastDayISO
}

export const thisMonthFirstDay = (ISODate) => {
    const today = new Date(ISODate || new Date()); // This gets the current date and time
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    const lastDayISO = new Date(lastDayOfMonth.setHours(0, 0, 0, 0)).toISOString();
    return lastDayISO
}