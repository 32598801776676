import React, { useState } from 'react'
import * as XLSX from 'xlsx';
import './choose-box.scss'
import { customerAxios } from '../../../config/axios'
import toast from 'react-hot-toast'
import Spinner from '../../common/spinners/SpinWithMessage'
import { TbHandClick } from "react-icons/tb";
import { YYYYMMDDFormat } from '../../../assets/javascript/formate-functions';

const CustomerDownloadBox = ({ setModal }) => {
    const [loading, setLoading] = useState('')
    const [progress, setProgress] = useState(0);


    const downloadFile = (workbook, filename) => {
        const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx', bookSST: true, type: 'array', cellDates: true, cellNF: false
        });

        const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
        filename = filename + '.xlsx';

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            // For IE browser
            window.navigator.msSaveOrOpenBlob(data, filename);
            setLoading('')
            setModal({ status: false })
        } else {
            // For other browsers
            const url = window.URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href = url;
            link.download = filename;
            link.click();
            window.URL.revokeObjectURL(url);
            setLoading('')
            setModal({ status: false })
        }
    }

    const exportToExcel = (customer) => {

        const workbook = XLSX.utils.book_new();
        // DAR Setup
        const sheetName1 = 'CustomerDetails';
        let workSheetData1 = []
        customer.map((data, index) => {
            const obj = {
                customer_creation_type: data?.purifier_details?.creation_type || data?.whole_house_details?.creation_type,
                cid: data?.cid,
                name: data?.first_name + ' ' + data?.last_name,
                address: data?.address?.address,
                land_mark: data?.address?.land_mark,
                place: data?.address?.place,
                post_office: data?.address?.post,
                pin: data?.address?.pin_code,
                district: data?.address?.district,
                zone: data?.zone_name,
                contact1: data?.contact1,
                contact2: data?.contact2,
                contact_alternative: null,
                whatsapp1: data?.whatsapp1,
                whatsapp_alternative: null,
                compliment_amount: data?.debit_amount,
                credit_amount: data?.credit_amount,
                purifier_name: `${data?.purifier_name || ''} ${data?.purifier_category || ''}`,
                purifier_installation_date: data?.purifier_details?.installed_at ? new Date(data?.purifier_details?.installed_at) : "",
                who_installed_the_purifier: data?.who_installed_the_purifier,
                purifier_customer_status: data?.purifier_customer_status,
                ssp_card_number: data?.purifier_details?.ssp_card_number,
                carbon_usage_start_date: data?.purifier_details?.carbon_filter_start_date ? new Date(data?.purifier_details?.carbon_filter_start_date) : "",
                purifier_next_periodical_service_date: data?.purifier_details?.next_periodical_service_date ? new Date(data?.purifier_details?.next_periodical_service_date) : "",
                carbon_usage_expiry_date: data?.purifier_details?.carbon_filter_expiry_date ? new Date(data?.purifier_details?.carbon_filter_expiry_date) : "",
                purifier_service_package_start_from: data?.purifier_details?.package_started_date ? new Date(data?.purifier_details?.package_started_date) : "",
                purifier_service_package_end_on: data?.purifier_details?.package_expiry_date ? new Date(data?.purifier_details?.package_expiry_date) : "",
                no_of_given_service_of_purifier: data?.purifier_details?.service_count?.length || 0,
                purifier_technician_last_visited_on: data?.purifier_details?.technician_last_visited_date ? new Date(data?.purifier_details?.technician_last_visited_date) : "",
                purifier_product_price: data?.purifier_details?.product_price_at_install,
                purifier_bill_date: data?.purifier_details?.bill_received_date ? new Date(data?.purifier_details?.bill_received_date) : "",
                purifier_service_section_admin_description: null,
                purifier_service_section_technician_description: null,
                wh_name: data?.wh_name,
                wh_customer_status: data?.wh_customer_status,
                wh_installation_date: data?.whole_house_details?.installed_at ? new Date(data?.whole_house_details?.installed_at) : "",
                who_installed_the_wh: data?.who_installed_the_wh,
                wh_refilling_date: data?.whole_house_details?.last_refilling_date ? new Date(data?.whole_house_details?.last_refilling_date) : "",
                who_refilled_the_wh: data?.who_refilled_the_wh,
                wh_product_price: data?.whole_house_details?.product_price_at_install,
                wh_bill_date: data?.whole_house_details?.bill_received_date ? new Date(data?.whole_house_details?.bill_received_date) : "",
                wh_refilling_price: null,
                package_product_price: data?.package_price_at_install,
                package_bill_date: data?.purifier_details?.bill_received_date || data?.whole_house_details?.bill_received_date ? new Date(data?.purifier_details?.bill_received_date || data?.whole_house_details?.bill_received_date) : "",
                enquiry_type: data?.purifier_details?.enquiry_type || data?.whole_house_details?.enquiry_type,
                enquiry_serial_no: data?.purifier_details?.enquiry_srl_no || data?.whole_house_details?.enquiry_srl_no,
                who_collected_the_enquiry: data?.who_collected_the_enquiry,
                care_of_id: data?.care_of_id,
                care_of_whom: data?.purifier_details?.care_of_whom || data?.whole_house_details?.care_of_whom,
                other_product_name: null,
                other_product_customer_status: null,
                other_product_installation_date: null,
                other_product_price: null,
                other_product_bill_date: null,
                rating_star: data?.star_rate

            }
            workSheetData1.push(obj)
            return obj;
        })

        const worksheet1 = XLSX.utils.json_to_sheet(workSheetData1);
        XLSX.utils.book_append_sheet(workbook, worksheet1, sheetName1);

        return workbook;
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading('Data fetching')
        customerAxios.get('/profile/download', {
            onDownloadProgress: (progressEvent) => {
                const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setProgress(percentage);
            }
        }).then((response) => {
            const workbook = exportToExcel(response.data || [])
            downloadFile(workbook, `CustomerDetails`)
        }).catch((error) => {
            toast.error('Something wrong, Try again!')
        })


    }


    return (
        <div className="dar-download-box-div">

            <form action="" onSubmit={handleSubmit}>
                {loading
                    ? <div className="loading-box-div">
                        <Spinner height={'150px'} message={`${progress}% \n ${loading}...`} />
                    </div>
                    : <>
                        <div className="content-box">
                            <TbHandClick />
                            <p>Click button for Download Excel file</p>
                        </div>
                        <div className="button-div">
                            <button type={'submit'}>Download</button>
                        </div>
                    </>}
            </form>

        </div>
    )
}

export default CustomerDownloadBox