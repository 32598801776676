import { Font } from '@react-pdf/renderer';

import InconsolataExtraLight from './fonts/inconsolata/Inconsolata_SemiCondensed-ExtraLight.ttf'
import InconsolataLight from './fonts/inconsolata/Inconsolata_SemiCondensed-Light.ttf'
import InconsolataRegular from './fonts/inconsolata/Inconsolata_SemiCondensed-Regular.ttf'
import InconsolataMedium from './fonts/inconsolata/Inconsolata_SemiCondensed-Medium.ttf'
import InconsolataSemiBold from './fonts/inconsolata/Inconsolata_SemiCondensed-SemiBold.ttf'
import InconsolataBold from './fonts/inconsolata/Inconsolata_SemiCondensed-Bold.ttf'
import InconsolataExtraBold from './fonts/inconsolata/Inconsolata_SemiCondensed-ExtraBold.ttf'
import InconsolataBlack from './fonts/inconsolata/Inconsolata_SemiCondensed-Black.ttf'

// Manjari
import ManjariThin from './fonts/manjari/Manjari-Thin.ttf'
import ManjariRegular from './fonts/manjari/Manjari-Regular.ttf'
import ManjariBold from './fonts/manjari/Manjari-Bold.ttf'



Font.register({
    family: 'PdfFontFamily',
    format: 'truetype',
    fonts: [
        // Inconsolata
        { src: InconsolataExtraLight, fontWeight: 200 },
        { src: InconsolataLight, fontWeight: 300 },
        { src: InconsolataRegular, fontWeight: 400 },
        { src: InconsolataMedium, fontWeight: 500 },
        { src: InconsolataSemiBold, fontWeight: 600 },
        { src: InconsolataBold, fontWeight: 700 },
        { src: InconsolataExtraBold, fontWeight: 800 },
        { src: InconsolataBlack, fontWeight: 900 },

        // Manjari
        { src: ManjariThin, fontWeight: 1100 },
        { src: ManjariRegular, fontWeight: 1400 },
        { src: ManjariBold, fontWeight: 1700 },
    ],
});

