import React from 'react'
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { formatStringDate } from '../../../assets/javascript/formate-functions'

function Info({ data }) {
    const styles = StyleSheet.create({
        parent: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingBottom: '15px',
            borderBottom: '1px solid #d4d4d4',
            gap: '10px',
            marginBottom: '5px'
        },
        head: {
            fontWeight: 600,
            fontSize: '11px',
            marginBottom: '7px',
        },
        text: {
            marginBottom: '2px',
            fontSize: '11px',
            fontWeight: 400
        }
    })
    return (

        <View style={styles.parent} fixed>
            <View style={{ textAlign: 'left', overflow: 'hidden' }}>
                <Text style={styles.head}>Billing Address,</Text>
                <Text style={styles.text}>Alliance Water Solutions</Text>
                <Text style={styles.text}>Chalingad PO</Text>
                <Text style={styles.text}>Kaipamangalam, Thrissur</Text>
                <Text style={styles.text}>Pin code: 680 681</Text>
                <Text style={styles.text}>Mobile: 953 953 9453</Text>
            </View>
            <View style={{ textAlign: 'left', overflow: 'hidden' }}>
                <Text style={styles.head}>Billing To,</Text>
                <Text style={styles.text}>Customer ID : {data?.cid}</Text>
                <Text style={styles.text}>{data?.name_of_customer}</Text>
                {data?.address?.address && <Text style={styles.text}>{data?.address?.address}</Text>}
                {(data?.address?.place || data?.address?.post) && <Text style={styles.text}>{data?.address?.place && `${data?.address?.place}, `}{data?.address?.post && `${data?.address?.post} (P.O)`}</Text>}
                {(data?.address?.district || data?.address?.pin_code) && <Text style={styles.text}>{data?.address?.district && `${data?.address?.district} Dt. `}{data?.address?.pin_code && `- ${data?.address?.pin_code}`}</Text>}
                <Text style={styles.text}>Mobile : {data?.contact1?.number || data?.contact2?.number || "Nill"}</Text>
            </View>
            <View style={{ textAlign: 'left', overflow: 'hidden' }}>
                <Text style={styles.text}>Date : {formatStringDate(data?.date)}</Text>
                <Text style={styles.text}>Srl no : {data?.service_srl_number}</Text>
                {data?.current_ssp_card_no && <Text style={styles.text}>SSP Card : {data?.current_ssp_card_no}</Text>}
                <Text style={styles.text}>Status : {data?.purifier_customer_status}</Text>
                <Text style={styles.text}>Work : {data?.work_method}</Text>
            </View>
        </View>

    )
}

export default Info