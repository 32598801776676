import React, { useEffect, useState } from 'react'
import './style.scss'
import { useSearchParams } from 'react-router-dom'
import { customerAxios } from '../../config/axios'
import BillPdf from '../../components/build-pdf/BillPdf'
import ReceiptPdf from '../../components/build-pdf/ReceiptPdf'
import { pdf } from '@react-pdf/renderer';
import { BiLoaderAlt } from "react-icons/bi";
import { FaRegCircleCheck } from "react-icons/fa6";
import { CgCloseO } from "react-icons/cg";

const BillReceiptBuilder = () => {
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams()
    const [loading, setLoading] = useState('fetch_data')
    const [errorMsg, setErrorMsg] = useState('')
    const [pdfBlobData, setPdfBlobData] = useState(null)
    const [pdfFileName, setPdfFileName] = useState('')

    const buildBill = async (data) => {
        setLoading('bill')
        const pdfDoc = (<BillPdf data={data} />);
        const pdfBlob = await pdf(pdfDoc).toBlob();
        downloadPdf(pdfBlob, `Bill-${data?.service_srl_number}`)
        setPdfBlobData(pdfBlob)
        setPdfFileName(`Bill-${data?.service_srl_number}`)
    }

    const buildReceipt = async (data, total) => {
        setLoading('receipt')
        const pdfDoc = (<ReceiptPdf data={data} totalConfirmAmount={total} />);
        const pdfBlob = await pdf(pdfDoc).toBlob();
        downloadPdf(pdfBlob, `Receipt-${data.service_srl_number}`)
        setPdfBlobData(pdfBlob)
        setPdfFileName(`Receipt-${data.service_srl_number}`)
    }


    const downloadPdf = (blobData, fileName) => {
        const pdfUrl = URL.createObjectURL(blobData);

        // Create an anchor element
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.target = '_blank';
        link.rel = 'noopener noreferrer';

        // Trigger the download programmatically
        if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
            // For iPhone/iPad devices, use the 'click' event instead of 'download'
            const clickEvent = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: false
            });
            link.dispatchEvent(clickEvent);

        } else {
            // For other devices, set the 'download' attribute and click the link
            link.download = `${fileName}.pdf`;
            link.click();
        }
        setLoading('')
    }


    useEffect(() => {
        document.title = "Payment | Alliance water solutions";
        if (searchParams.get('srl_number') && searchParams.get('cid') && searchParams.get('date')) {
            customerAxios.get(`/payment/pdf/purifier-service?srl_number=${searchParams.get('srl_number')}&cid=${searchParams.get('cid')}&date=${searchParams.get('date')}`).then((response) => {
                let total = 0
                response.data?.payment_history?.map((tran) => {
                    if (tran.confirm) {
                        total += tran.total_amount
                    }
                    return tran;
                })

                setLoading('build_pdf')

                if (searchParams.get('type') === 'Bill') {
                    buildBill(response.data)
                    return;
                }
                if (searchParams.get('type') === 'Receipt') {
                    buildReceipt(response.data, total)
                    return;
                }

                // if (!response.data) {
                setErrorMsg('Data not found')
                // }
            }).catch(() => {
                setLoading('')
                setErrorMsg('Data not found')
            })
        } else {
            window.close()
            setErrorMsg('URL not found')
        }

        const intervalId = setInterval(() => {
            window.close()
        }, 30000); // every 1000ms (1 second)

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);

        // eslint-disable-next-line
    }, [])


    return (
        <div className="download-window-div">
            <div className={errorMsg ? "box-div red-color" : loading ? 'box-div' : 'box-div green-color'}>
                {errorMsg ? <>
                    <div className="icon-div">
                        <CgCloseO />
                    </div>
                    <h4>{errorMsg}</h4>
                    <div className="button-div">
                        <button className='button close' onClick={() => window.close()}>Close</button>
                    </div>
                </>
                    :
                    <>
                        <div className="icon-div">
                            {loading ?
                                <span className='loading-icon'><BiLoaderAlt /></span>
                                : <FaRegCircleCheck />}
                        </div>
                        <h4>{loading === 'fetch_data' ? 'Fetch data...' : loading ? 'PDF Building...' : 'Ready to Download'}</h4>
                        <div className="button-div">
                            {pdfFileName && <button className='button' onClick={() => downloadPdf(pdfBlobData, pdfFileName)}>Download</button>}
                            <button className='button close' onClick={() => window.close()}>Close</button>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default BillReceiptBuilder