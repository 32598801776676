import React, { useEffect, useState } from 'react'
import './add-customer.scss'
import NormalInput from '../../common/inputs/NormalInput'
import SelectInput from '../../common/inputs/SelectInput'
import { BiLoaderAlt } from 'react-icons/bi'
import { customerAxios, purifierAxios } from '../../../config/axios'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

const AddCustomer = () => {
    const [loading, setLoading] = useState('fetch')
    const [zoneList, setZoneList] = useState([])
    const [states, setStates] = useState([])
    const [districts, setDistricts] = useState([])
    const [posts, setPosts] = useState([])
    const [form, setForm] = useState({})
    const navigate = useNavigate()

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })


        if (e.target.name === 'state') {
            states?.map((obj) => {
                if (obj.option === e.target.value) {
                    const dtList = (obj?.districts || [])?.map((dt) => ({ option: dt.name, value: dt.name, posts: dt.posts }))
                    setDistricts([...dtList, { option: 'other', value: 'other' }])
                }
                return obj;
            })

            setForm({
                ...form,
                [e.target.name]: e.target.value,
                district: null,
                post: null
            })
        }

        if (e.target.name === 'district') {
            districts?.map((obj) => {
                if (obj.option === e.target.value) {
                    const postList = (obj?.posts || [])?.map((dt) => ({ option: dt.name, value: dt.name, pin: dt.pin_code }))
                    setPosts([...postList, { option: 'other', value: 'other' }])
                }
                return obj
            })

            setForm({
                ...form,
                [e.target.name]: e.target.value,
                post: null
            })
        }

        if (e.target.name === 'post') {
            let pin = null
            posts?.map((obj) => {
                if (obj.option === e.target.value) {
                    pin = obj.pin
                }
                return obj;
            })
            setForm({
                ...form,
                [e.target.name]: e.target.value,
                pin_code: pin || form?.pin_code
            })

        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (form.first_name?.[0] === ' ' || form.last_name?.[0] === ' ') {
            toast.error('Remove space from first name and last name')
            return;
        }

        if (form.first_name.length < 3) {
            toast.error('First name must have minimum 3 length')
            return;
        }

        if (form.contact_1 === form.contact_2) {
            toast.error('Contact 1 and Contact 2 is Same')
            return;
        }

        if (!form.state || !form.district || !form.post) {
            toast.error('State, District or Post Not selected')
            return;
        }


        setLoading('submit')
        customerAxios.post('/profile', form).then((response) => {
            toast.success('New Customer created')
            navigate(`/customer/${response.data.cid}/edit`)
            setLoading('')
        }).catch((error) => {
            toast.error(error.message)
            setLoading('')
        })

    }

    useEffect(() => {
        purifierAxios.get('/zone').then((response) => {
            const zones = response?.data || []
            setZoneList(zones.map((obj) => ({ option: obj.zone, value: obj.zone_id })))
        })

        customerAxios.get('/post-office-list').then((response) => {
            const stateList = (response?.data || []).map((obj) => ({ option: obj.state, value: obj.state, districts: obj.districts }))
            setStates([...stateList, { option: 'other', value: 'other' }])
        })
    }, [])

    return (
        <div className="create-customer-div">
            <form action="" onSubmit={handleSubmit}>
                <div className="inputs">
                    <NormalInput label='First name*' value={form?.first_name} name='first_name' onChangeFun={handleChange} />
                    <NormalInput label='Last name*' value={form?.last_name} name='last_name' onChangeFun={handleChange} />
                    <NormalInput label='Address' value={form?.address} name='address' onChangeFun={handleChange} isRequired={false} />
                    <NormalInput label='Place*' value={form?.place} name='place' onChangeFun={handleChange} isRequired={false} />
                    <SelectInput label='State*' value={form?.state} name='state' values={states} onChangeFun={handleChange}
                        firstOption={{ option: 'Choose...', value: '' }} isRequired={false}/>
                    <SelectInput label='District*' value={form?.district} name='district' values={districts} onChangeFun={handleChange}
                        firstOption={{ option: 'Choose...', value: '' }} isRequired={false}/>
                    <SelectInput label='Post*' value={form?.post} name='post' values={posts} onChangeFun={handleChange}
                        firstOption={{ option: 'Choose...', value: '' }} isRequired={false}/>
                    <NormalInput label='Pin code' value={form?.pin_code} name='pin_code' onChangeFun={handleChange} isRequired={false} />
                    <NormalInput label='Land mark' value={form?.land_mark} name='land_mark' onChangeFun={handleChange} isRequired={false} />
                    <NormalInput label='Mobile number (primary)*' value={form?.contact_1} name='contact_1' onChangeFun={handleChange}
                        min={5555555555} max={9999999999} type={'number'} />
                    <NormalInput label='Mobile number (secondary)' value={form?.contact_2} name='contact_2' onChangeFun={handleChange} isRequired={false}
                         type={'number'} />
                    <NormalInput label='Whatsapp' value={form?.whatsapp} name='whatsapp' onChangeFun={handleChange} isRequired={false}
                        min={5555555555} max={9999999999} type={'number'} />
                    <SelectInput label='Zone*' value={form?.zone} values={zoneList} firstOption={{ option: 'Choose...', value: '' }}
                        name='zone' onChangeFun={handleChange} />
                    <NormalInput label='My Care of ID' value={form?.care_of_id} name='care_of_id' onChangeFun={handleChange} isRequired={false} />
                </div>

                <div className="button-div">
                    <button type='submit'>{loading === 'submit' ? <span className='loading-icon'><BiLoaderAlt /></span> : "Create New"}</button>
                </div>
            </form>
        </div>
    )
}

export default AddCustomer