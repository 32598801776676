import React from 'react'
import SinglePage from '../../components/common/page/SinglePage'
import CustomerDetails from '../../components/user/customer-details/CustomerCardDetails'
import { useParams } from 'react-router-dom';

const CustomerFullView = () => {
    const { cid } = useParams();


    return (
        <div className="customer-full-view-div">
            <SinglePage titleArray={[`Customer full view / ${cid}`]}>
                <CustomerDetails cid={cid} />
            </SinglePage>
        </div>
    )
}

export default CustomerFullView